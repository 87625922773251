















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import _, { find, findLast } from 'lodash';
import { latLng } from 'leaflet';
import { LCircle, LLayerGroup, LMarker } from 'vue2-leaflet';
import { MessageDetectionSupplementary, MessageWaveDetected } from '@/models/message.model';
import { WaveCircle } from '@/models/map.model';
import { CYAN_COLOR } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { MapService } from '@/services/map.service';

@Component({
  name: 'MapProxyCircles',
  components: {
    LMarker,
    LCircle,
    LLayerGroup,
  },
})
export default class MapProxyCircles extends Vue {
  @Prop() public waveMessages!: MessageWaveDetected[];
  @Prop() public detectionSupplementaryMessages!: MessageDetectionSupplementary[];
  @Prop() public stations!: Station[];

  public proxyWaveCircles: WaveCircle[] = [];

  public mounted() {
    this.calculateCircles();
  }

  @Watch('waveMessages')
  public onWaveMessageChange() {
    this.calculateCircles();
  }

  @Watch('detectionSupplementaryMessages')
  public onDetectionSupplementaryMessages() {
    this.calculateCircles();
  }

  @Emit('onCirclesChange')
  public onCirclesChange() {
    return MapService.getCircleBounds(this.$refs.proxyCircles as LCircle[]);
  }

  private calculateCircles() {
    this.proxyWaveCircles = _(this.stations)
      .map((station) => {
        return this.decideCircleToDraw(station.stationId);
      })
      .flatten()
      .value();
    setTimeout(() => {
      this.onCirclesChange();
    }, 100);
  }

  private decideCircleToDraw(stationId: string) {
    const lastWaveMessage = findLast(this.waveMessages, { stationId });
    const lastDetectionSupplementaryMessage = findLast(this.detectionSupplementaryMessages, { stationId });

    if (lastWaveMessage && lastDetectionSupplementaryMessage) {
      return this.getProxyWaveCircles(
        lastWaveMessage.stationId,
        lastWaveMessage.eventId,
        lastDetectionSupplementaryMessage.minDist,
        lastDetectionSupplementaryMessage.time >= lastWaveMessage.time
          ? lastDetectionSupplementaryMessage.maxDist
          : lastWaveMessage.proxyDistance.max,
      );
    }

    if (lastWaveMessage) {
      return this.getProxyWaveCircles(
        lastWaveMessage.stationId,
        lastWaveMessage.eventId,
        lastWaveMessage.proxyDistance.min,
        lastWaveMessage.proxyDistance.max,
      );
    }

    if (lastDetectionSupplementaryMessage) {
      return this.getProxyWaveCircles(
        lastDetectionSupplementaryMessage.stationId,
        lastDetectionSupplementaryMessage.eventId,
        lastDetectionSupplementaryMessage.minDist,
        lastDetectionSupplementaryMessage.maxDist,
      );
    }

    return [];
  }

  private getProxyWaveCircles(stationId: string, eventId: string, min: number, max: number) {
    const station = find(this.stations, { stationId });
    if (!station) {
      return [];
    }

    return [
      {
        key: `proxyMin${eventId}_${Math.random() * 1000}`,
        center: latLng(station.location.lat, station.location.long),
        radius: min ? min * 1000 : 0,
        color: CYAN_COLOR,
      },
      {
        key: `proxyMax${eventId}_${Math.random() * 1000}`,
        center: latLng(station.location.lat, station.location.long),
        radius: max ? max * 1000 : 0,
        color: CYAN_COLOR,
      },
    ];
  }
}
