// eslint-disable-next-line max-classes-per-file
import _ from 'lodash';

export interface WaveCircle {
  key: string;
  center: any;
  radius: number;
  color: string;
}

export interface Marker {
  icon: any;
  iconHover?: any;
  key: string;
  location: number[];
  name: string;
  meta?: any;
  stationId?: string;
  zIndex?: number;
}

const specialCaseRouteNames: string[] = ['simulation-analysis', 'event-regional', 'event-details'];

export class FilterFlags {
  public polygon: boolean = true;
  public stations: boolean = true;
  public baz: boolean = true;
  public pois: boolean = true;
  public poisActive: boolean = true;
  public intensityLayers: boolean = false;
  public epicenter: boolean = true;
  public catalogEpicenter: boolean = true;
  public distance: boolean = true;
  public wavefronts: boolean = true;
  public intensityPolygons: boolean = true;
  public shezifAlerts: boolean = true;
  public vidaArray: boolean = true;

  constructor(routeName: string = '') {
    if (_.includes(specialCaseRouteNames, routeName)) {
      this.distance = routeName === 'event-details';
      this.baz = routeName === 'event-details';
    }
    if (routeName === 'real-time-monitor') {
      this.polygon = false;
    }
  }
}

export class FilterFlagsEnableStatus {
  public polygon: boolean = true;
  public stations: boolean = true;
  public baz: boolean = true;
  public pois: boolean = true;
  public poisActive: boolean = true;
  public intensityLayers: boolean = true;
  public epicenter: boolean = true;
  public catalogEpicenter: boolean = true;
  public distance: boolean = true;
  public wavefronts: boolean = true;
  public intensityPolygons: boolean = true;
  public shezifAlerts: boolean = true;
  public vidaArray: boolean = true;

  constructor(routeName: string = '') {
    if (_.includes(specialCaseRouteNames, routeName)) {
      this.polygon = _.includes(['simulation-analysis', 'event-regional'], routeName);
      this.intensityPolygons = _.includes(['simulation-analysis', 'event-regional'], routeName);
      this.epicenter = _.includes(['simulation-analysis', 'event-regional'], routeName);
      this.shezifAlerts = _.includes(['event-regional'], routeName);
      this.vidaArray = _.includes(['event-regional'], routeName);
    }
    if (routeName === 'real-time-monitor') {
      this.polygon = false;
    }
    // this.intensityLayers = routeName === 'real-time-monitor';
  }
}
