










import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { LMarker, LTooltip } from 'vue2-leaflet';
import { Marker } from '@/models/map.model';
import { HumanizedEvent } from '@/models/event.model';
import { iconEpicenter } from '@/constants/leaflet.constants';

@Component({
  name: 'MapEventMarkers',
  components: {
    LMarker,
    LTooltip,
  },
})
export default class MapEventMarkers extends Vue {
  @Prop() public events!: HumanizedEvent[];

  public markers: Marker[] = [];

  public mounted() {
    this.onEventsChange();
  }

  @Emit('onMarkersChange')
  public onMarkersChange() {
    return this.markers.map((marker) => marker.location);
  }

  @Watch('events')
  public onEventsChange() {
    this.markers = this.events
      .filter((event) => event.eqLocation)
      .map((event) => {
        return {
          key: `event_${event.eventId}`,
          name: event.name,
          icon: iconEpicenter,
          location: [event.eqLocation.lat, event.eqLocation.long],
          meta: {
            event,
          },
        };
      });
    this.onMarkersChange();
  }

  public onMarkerClick(marker: Marker) {
    if (marker.meta.event && marker.meta.event.regionalEventId) {
      this.$router.push({ name: 'event-regional', params: { eventId: marker.meta.event.eventId } });
      return;
    }

    if (marker.meta.event && marker.meta.event.eventId) {
      this.$router.push({ name: 'event', params: { eventId: marker.meta.event.eventId } });
    }
  }
}
