


























































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MessageSource } from '@/models/message.model';
import { PointOfInterestComplete } from '@/models/position.model';
import { roundToDecimal } from '@/filters/number.filter';
import { SpeechService } from '@/services/speech.service';

@Component({
  name: 'MapSidebar',
})
export default class MapSidebar extends Vue {
  @Prop() public pointsOfInterest!: PointOfInterestComplete[];
  @Prop() public sourceMessage!: MessageSource;
  @Prop() public eventEndedAt!: string;

  public firstSourceTreated: boolean = false;
  public timeCorrectionFactor: number = 7; // seconds

  public mounted() {
    this.firstSourceTreated = false;
  }

  public round(value: number) {
    return roundToDecimal(value, 2);
  }

  @Watch('sourceMessage')
  public onSourceMessageChange() {
    if (this.firstSourceTreated) {
      return;
    }
    const pois = _.sortBy(this.pointsOfInterest, 'distanceToHypocenterKm');
    const selectedPoi: PointOfInterestComplete | null = pois[0] || null;
    const minMagnitude = roundToDecimal(this.sourceMessage.magnitude.min, 2);

    let playedMessage = '';

    if (selectedPoi) {
      const distance = roundToDecimal(selectedPoi.distanceToHypocenterKm, 2);
      playedMessage = `An earthquake was detected ${distance} km from ${selectedPoi.name}. `;
    }

    playedMessage += `Estimated minimal magnitude is ${minMagnitude}.`;

    if (selectedPoi) {
      playedMessage += ` Estimated shake arrival time to ${selectedPoi.name} is ${roundToDecimal(
        selectedPoi.sLeadTime - (this.sourceMessage.millisSinceFirstPDetected / 1000 + this.timeCorrectionFactor),
        2,
      )} seconds.`;
    }

    SpeechService.playUtterance(SpeechService.getUtterance(playedMessage, () => {}));
    this.firstSourceTreated = true;
  }

  @Watch('eventEndedAt')
  public onEventEndedAtChange() {
    const minMagnitude = roundToDecimal(this.sourceMessage.magnitude.min, 2);

    const playedMessage = `Seismic event ended at ${this.eventEndedAt}.  The minimal magnitude was ${minMagnitude}`;

    SpeechService.playUtterance(SpeechService.getUtterance(playedMessage, () => {}));
  }
}
