import _ from 'lodash';
import axios from 'axios';
import { MESSAGE_TYPES, START_EVENTS } from '@/constants/message-types.constant';
import { Options } from '@/models/app.model';
import {
  Message,
  MessageAlert,
  MessageDetectionSupplementary,
  MessagePRefined,
  MessageSloBazDetermined,
  MessageSource,
  MessagesQueryParams,
  MessagesQueryResponse,
  MessagesRealtimeQueryParams,
  MessagesRealtimeResponse,
  MessageVIDA,
  MessageWaveDetected,
  SeismicEventInProgress,
} from '@/models/message.model';
import { Station } from '@/models/station.model';
import { getDateTime } from '@/filters/time.filter';
import StationService from '@/services/station.service';
import { EventRegional } from '@/models/event.model';

export class MessagesService {
  public static getRaw(messageId: number): Promise<any> {
    return axios
      .get<any>(`/messages/${messageId}/raw`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.data);
  }

  public static query(params: Partial<MessagesQueryParams> = {}): Promise<MessagesQueryResponse> {
    return axios.post<MessagesQueryResponse>(`/messages/query`, params).then((response) => response.data);
  }

  public static realtimeQuery(params: MessagesRealtimeQueryParams): Promise<MessagesRealtimeResponse> {
    return axios.post<MessagesRealtimeResponse>(`/messages/realtime/query`, params).then((response) => response.data);
  }

  public static parseMessage(message: Message): Options<string>[] {
    if (message.type === MESSAGE_TYPES.SOURCE) {
      return MessagesService.parseSource(message as MessageSource);
    }
    if (message.type === MESSAGE_TYPES.DETECTION_SUPPLEMENTARY) {
      return MessagesService.parseDetectionSupplementary(message as MessageDetectionSupplementary);
    }
    if (message.type === MESSAGE_TYPES.P_DETECTED) {
      return MessagesService.parsePDetected(message as MessageWaveDetected);
    }
    if (message.type === MESSAGE_TYPES.S_DETECTED) {
      return MessagesService.parsePDetected(message as MessageWaveDetected);
    }
    if (message.type === MESSAGE_TYPES.P_SLOBAZ_DETERMINED) {
      return MessagesService.parseSloBazOrPRefined(message as MessageSloBazDetermined);
    }
    if (message.type === MESSAGE_TYPES.P_REFINED) {
      return MessagesService.parseSloBazOrPRefined(message as MessagePRefined);
    }
    return MessagesService.parseEnded(message);
  }

  public static parseDetectionSupplementary(message: MessageDetectionSupplementary): Options<string>[] {
    return [
      {
        name: 'Time',
        value: getDateTime(message.time),
        icon: 'mdi-clock',
      },
      {
        name: 'Magnitude',
        value: MessagesService.renderInterval(message.minMag, message.maxMag, 3),
        icon: 'mdi-chart-snakey-variant',
      },
      {
        name: 'RMS AMP A',
        value: `${message.rmsAmpA}`,
      },
      {
        name: 'RMS AMP D',
        value: `${message.rmsAmpD}`,
      },
      {
        name: 'RMS AMP V',
        value: `${message.rmsAmpV}`,
      },
      {
        name: 'Peak AMP A',
        value: `${message.peakAmpA}`,
      },
      {
        name: 'Peak AMP D',
        value: `${message.peakAmpD}`,
      },
      {
        name: 'Peak AMP V',
        value: `${message.peakAmpV}`,
      },
      {
        name: 'Time Past P',
        value: `${message.time_pastP}`,
      },
      {
        name: 'S Minus P',
        value: `${message.sminusP}`,
      },
      {
        name: 'Q Factor',
        value: `${message.qfactor}`,
      },
      {
        name: 'S2N Ratio',
        value: `${message.s2nRatio}`,
      },
      {
        name: 'Distance',
        value: MessagesService.renderInterval(message.minDist, message.maxDist, 1, ' Km'),
      },
    ];
  }

  private static renderInterval(from: number, to: number, decimals: number = 6, units: string = ''): string {
    const fromMissing = !from || from > 1e100 || from < -1e100;
    const toMissing = !to || to > 1e100 || to < -1e100;

    if (fromMissing && toMissing) {
      return 'null';
    }

    const fromStr = fromMissing ? 'na' : `${from.toFixed(decimals)}${units}`;
    const toStr = toMissing ? 'na' : `${to.toFixed(decimals)}${units}`;

    return `${fromStr} - ${toStr}`;
  }

  public static parseSource(message: MessageSource): Options<string>[] {
    return [
      {
        name: 'Time',
        value: getDateTime(message.time),
        icon: 'mdi-clock',
      },
      {
        name: 'Magnitude',
        value: `${message.magnitude.min} - ${message.magnitude.max}`,
        icon: 'mdi-chart-snakey-variant',
      },
      {
        name: 'Time since First P Detected',
        value: `${message.millisSinceFirstPDetected}ms`,
        icon: 'mdi-clock',
      },
      {
        name: 'P Wave Front Distance',
        value: `${message.pWaveFrontDistance}`,
      },
      {
        name: 'S Wave Front Distance',
        value: `${message.sWaveFrontDistance}`,
      },
    ];
  }

  public static parsePDetected(message: MessageWaveDetected): Options<string>[] {
    return [
      {
        name: 'Time',
        value: getDateTime(message.time),
        icon: 'mdi-clock',
      },
      {
        name: 'Arrival Time',
        value: getDateTime(message.arrivalTime),
        icon: 'mdi-clock',
      },
      {
        name: 'Quality',
        value: `${message.quality}`,
      },
    ];
  }

  public static parseSloBazOrPRefined(message: MessageSloBazDetermined): Options<string>[] {
    return [
      {
        name: 'Time',
        value: getDateTime(message.time),
        icon: 'mdi-clock',
      },
      {
        name: 'Baz',
        value: `${message.baz}`,
      },
      {
        name: 'Baz Interval',
        value: `${message.minBaz} - ${message.maxBaz}`,
        icon: 'mdi-chart-snakey-variant',
      },
      {
        name: 'Slo',
        value: `${message.slo}`,
      },
      {
        name: 'Quality',
        value: `${message.quality}`,
      },
    ];
  }

  public static parseEnded(message: Message): Options<string>[] {
    return [
      {
        name: 'Time',
        value: getDateTime(message.time),
        icon: 'mdi-clock',
      },
    ];
  }

  public static getWaveMessages(messages: Message[]): MessageWaveDetected[] {
    return messages.filter((message: Message) => message.type === MESSAGE_TYPES.S_DETECTED) as MessageWaveDetected[];
  }

  public static getVIDAMessages(messages: Message[]): MessageVIDA[] {
    return messages.filter((message: Message) => message.type === MESSAGE_TYPES.VIDA) as MessageVIDA[];
  }

  public static getAlertMessages(messages: Message[]): MessageAlert[] {
    return messages.filter((message: Message) => message.type === MESSAGE_TYPES.ALERT) as MessageAlert[];
  }

  public static getDetectionSupplementaryMessages(messages: Message[]): MessageDetectionSupplementary[] {
    return messages.filter((message: Message) => message.type === MESSAGE_TYPES.DETECTION_SUPPLEMENTARY) as MessageDetectionSupplementary[];
  }

  public static getSloBazMessages(messages: Message[], ignorePRefined: boolean = false): MessageSloBazDetermined[] {
    return ignorePRefined
      ? (messages.filter((message: Message) => message.type === MESSAGE_TYPES.P_SLOBAZ_DETERMINED) as MessageSloBazDetermined[])
      : (messages
          .filter((message: Message) => message.type === MESSAGE_TYPES.P_SLOBAZ_DETERMINED)
          // make sure the P_REFINED messages are last so that they're the ones shown if they exist instead of P_SLOBAZ_DET
          .concat(messages.filter((msg) => msg.type === MESSAGE_TYPES.P_REFINED)) as MessageSloBazDetermined[]);
  }

  public static getSOHMessages(messages: Message[]): MessageSloBazDetermined[] {
    return messages.filter((message: Message) => message.type === MESSAGE_TYPES.SOH) as MessageSloBazDetermined[];
  }

  public static getSourceMessages(messages: Message[]): MessageSource[] {
    return messages.filter((message: Message) => message.type === MESSAGE_TYPES.SOURCE) as MessageSource[];
  }

  public static getEventEndedMessages(messages: Message[]): Message[] {
    return messages.filter((message: Message) => message.type === MESSAGE_TYPES.EVENT_ENDED);
  }

  public static getValidMessages(messages: Message[]): Message[] {
    return messages.filter((message: Message) => !_.isNull(message));
  }

  public static getAppendedMessages(currentMessages: Message[], newMessages: Message[], minTime: number): Message[] {
    return _(currentMessages)
      .union(newMessages)
      .reject((message: Message) => message.time <= minTime)
      .filter((message: Message) => !_.isNull(message))
      .value();
  }

  public static isSeismicEventInProgress(
    currentValue: SeismicEventInProgress | null,
    messages: Message[],
    stations: Station[],
  ): SeismicEventInProgress | null {
    const lastEndMessage =
      _(messages).findLast({ type: MESSAGE_TYPES.EVENT_ENDED }) ?? _(messages).findLast({ type: MESSAGE_TYPES.REGIONAL_EVENT_ENDED });
    const lastMessage = _.last(messages);

    if (lastMessage && _.includes(START_EVENTS, lastMessage.type)) {
      return {
        regionId: lastMessage.regionId,
        stationId: lastMessage.stationId,
        impactedStationIds: StationService.filterByRegion(stations, lastMessage.regionId).map((station) => station.stationId),
        startTime: lastMessage.time,
        endTime: -1,
      };
    }

    if (lastEndMessage) {
      return {
        regionId: lastEndMessage.regionId,
        stationId: lastEndMessage.stationId,
        impactedStationIds: StationService.filterByRegion(stations, lastEndMessage.regionId).map((station) => station.stationId),
        startTime: currentValue ? currentValue.startTime : -1,
        endTime: lastEndMessage.time,
      };
    }

    return currentValue;
  }

  public static getQueryPramsForRegionalEvent(event: EventRegional): Partial<MessagesQueryParams> {
    return {
      count: -1,
      start: 0,
      includeRegional: true,
      sort: { column: 'TIME', ascending: true },
      stationIds: event.stationEvents.map((stationEvent) => stationEvent.stationId),
      time: {
        from: event.startTime,
        to: event.endTime,
      },
    };
  }
}
