export class SpeechService {
  public static getUtterance(text: string, onEnd: () => void): SpeechSynthesisUtterance {
    const utterance = new SpeechSynthesisUtterance();
    utterance.lang = 'en-US';
    utterance.text = text;
    utterance.volume = 1;
    utterance.rate = 1;
    utterance.onend = onEnd;

    return utterance;
  }

  public static playUtterance(utterance: SpeechSynthesisUtterance) {
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utterance);
  }
}
