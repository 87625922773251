











import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { LPolygon, LPolyline, LTooltip } from 'vue2-leaflet';
import { MessageSource } from '@/models/message.model';
import { INTENSITY_COLORS } from '@/constants/app.constants';

@Component({
  name: 'MapIntensityPolygons',
  components: {
    LPolygon,
    LPolyline,
    LTooltip,
  },
})
export default class MapIntensityPolygons extends Vue {
  @Prop() public sourceMessage!: MessageSource;

  public INTENSITY_COLORS: { [key: string]: string } = INTENSITY_COLORS;
  public polygons: { color: string; index: string; polygon: number[][]; intensity: number }[] = [];
  public polylines: { color: string; index: string; polygon: number[][]; intensity: number }[] = [];

  public mounted() {
    this.onSourceMessageChange();
  }

  @Emit('onPolygonChange')
  public onPolygonChange() {
    return this.polygons.map((item) => item.polygon);
  }

  @Watch('sourceMessage')
  public onSourceMessageChange() {
    this.generatePolygons(this.sourceMessage);
  }

  private generatePolygons(sourceMessage: MessageSource) {
    this.polygons = sourceMessage?.intensityPolygons
      ? sourceMessage.intensityPolygons
          .filter((item) => item.closed)
          .map((item, index) => ({
            color: this.INTENSITY_COLORS[item.intensity],
            intensity: item.intensity,
            polygon: item.polygon,
            index: `poly_${index}`,
          }))
      : [];
    this.polylines = sourceMessage?.intensityPolygons
      ? sourceMessage.intensityPolygons
          .filter((item) => !item.closed)
          .map((item, index) => ({
            color: this.INTENSITY_COLORS[item.intensity],
            intensity: item.intensity,
            polygon: item.polygon,
            index: `line_${index}}`,
          }))
      : [];
    this.onPolygonChange();
  }
}
