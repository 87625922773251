

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { PointOfInterestComplete } from '@/models/position.model';
import { MessageSource } from '@/models/message.model';

@Component({
  name: 'MapDetails',
})
export default class MapDetails extends Vue {
  @Prop() public sourceMessage!: MessageSource;
  @Prop() public eventEndedAt!: string;

  public mounted() {}
}
