































import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { LMarker, LTooltip } from 'vue2-leaflet';
import { getIconForStatus } from '@/filters/status.filter';
import { Marker } from '@/models/map.model';
import { Station } from '@/models/station.model';
import { iconStationArrayTriggered } from '@/constants/leaflet.constants';

@Component({
  name: 'MapStationMarkers',
  components: {
    LMarker,
    LTooltip,
  },
})
export default class MapStationMarkers extends Vue {
  @Prop() public stations!: Station[];
  @Prop({ default: () => [] }) public impactedStations!: string[];
  @Prop() public zoom!: number;
  @Prop({ default: () => null }) public hoverOverStation?: string | null;

  public stationsGeometry: Marker[][] = [];
  public markers: Marker[] = [];
  public showStationGeometry: boolean = false;

  public mounted() {
    this.onStationsChange();
  }

  @Emit('onMarkersChange')
  public onMarkersChange() {
    return this.markers.map((marker) => marker.location);
  }

  @Watch('stations')
  public onStationsChange() {
    this.generateStationGeometry(this.stations);
    this.generateStationMarkers(this.stations);
  }

  @Watch('impactedStations')
  public onImpactedStationsChange() {
    this.onStationsChange();
  }

  @Watch('zoom')
  public onZoomChange() {
    this.showStationGeometry = this.zoom >= 15;
  }

  public onMarkerClick(marker: Marker) {
    this.$emit('onMarkerClick', marker.stationId);
  }

  private isStationImpacted(station: Station) {
    return this.impactedStations.includes(station.stationId);
  }

  private generateStationGeometry(stations: Station[]) {
    this.stationsGeometry = stations
      .filter((station) => station.location)
      .map((station) => {
        if (station.nodes?.length === 0) {
          return [
            {
              icon: getIconForStatus(station.healthStatus, false, station.type, this.isStationImpacted(station)),
              zIndex: this.isStationImpacted(station) ? 10 : 1,
              key: `m${station.stationId}`,
              name: station.stationId,
              location: [station.location.lat, station.location.long],
              stationId: station.stationId,
            },
          ];
        }

        return _(station.nodes)
          .map((node) => {
            if (node.location?.lat && node.location?.long) {
              return {
                icon: getIconForStatus(node.healthStatus, true, station.type, this.isStationImpacted(station)),
                zIndex: this.isStationImpacted(station) ? 10 : 1,
                name: `${station.stationId} Node ${node.nodeId}`,
                key: `mn${station.stationId}_${node.nodeId}`,
                location: [node.location.lat, node.location.long],
                stationId: station.stationId,
              };
            }
            return null;
          })
          .compact()
          .value();
      });
  }

  private generateStationMarkers(stations: Station[]) {
    this.markers = stations
      .filter((station) => station.location)
      .map((station) => {
        return {
          icon: getIconForStatus(station.healthStatus, false, station.type, this.isStationImpacted(station)),
          zIndex: this.isStationImpacted(station) ? 10 : 1,
          iconHover: iconStationArrayTriggered,
          key: `m${station.stationId}`,
          name: station.stationId,
          stationId: station.stationId,
          location: [station.location.lat, station.location.long],
        };
      });
    this.onMarkersChange();
  }
}
