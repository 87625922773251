
















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { LMarker, LTooltip } from 'vue2-leaflet';
import { getPoiColorFromType, getPoiIconFromType } from '@/filters/status.filter';
import { Marker } from '@/models/map.model';
import { PointOfInterestComplete } from '@/models/position.model';

@Component({
  name: 'MapPOIMarkers',
  components: {
    LMarker,
    LTooltip,
  },
})
export default class MapPOIMarkers extends Vue {
  @Prop() public pointsOfInterest!: PointOfInterestComplete[];
  @Prop() public showPoiInfo?: boolean;

  public markers: Marker[] = [];

  public mounted() {
    this.onPointsOfInterestChange();
  }

  @Emit('onMarkersChange')
  public onMarkersChange() {
    return this.markers.map((marker) => marker.location);
  }

  @Watch('pointsOfInterest')
  public onPointsOfInterestChange() {
    this.markers = this.pointsOfInterest.map((poi) => {
      return {
        key: `poi_${poi.id}`,
        name: poi.name,
        icon: getPoiIconFromType(poi.triggeredRule ? poi.triggeredRule.pgaThresholdType : null),
        color: getPoiColorFromType(poi.triggeredRule ? poi.triggeredRule.pgaThresholdType : ''),
        location: [poi.location.lat, poi.location.long],
        meta: {
          pLead: poi.pLeadTime,
          sLead: poi.sLeadTime ?? '-- ',
          pga: poi.expectedPga?.toExponential(2) ?? '-- ',
          distance: poi.distanceToHypocenterKm?.toFixed(2) ?? '-- ',
        },
      };
    });
    this.onMarkersChange();
  }
}
