






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { LMarker, LTooltip } from 'vue2-leaflet';
import { EventAdditionalLocation } from '@/models/event.model';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import { iconCatalogLocation } from '@/constants/leaflet.constants';

@Component({
  name: 'MapEventCatalogLocations',
  components: {
    ConfirmationDialog,
    LMarker,
    LTooltip,
  },
})
export default class MapEventCatalogLocations extends Vue {
  @Prop() public catalogLocations!: EventAdditionalLocation[];

  public iconCatalogLocation: any = iconCatalogLocation;
  public shouldRemove: boolean = false;
  public selectedCatalogLocation: EventAdditionalLocation | null = null;

  public removeCatalogLocation(catalogLocation: EventAdditionalLocation) {
    this.selectedCatalogLocation = catalogLocation;
    this.shouldRemove = true;
  }
}
