
















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { latLng } from 'leaflet';
import { LCircle } from 'vue2-leaflet';
import { MessageAlert } from '@/models/message.model';
import { WaveCircle } from '@/models/map.model';
import { ORANGE_COLOR } from '@/constants/app.constants';
import { MapService } from '@/services/map.service';

@Component({
  name: 'MapWaveCircles',
  components: {
    LCircle,
  },
})
export default class MapAlertCircles extends Vue {
  @Prop() public messages!: MessageAlert[];
  @Prop() public visible?: boolean;

  public alertCircles: WaveCircle[] = [];

  public mounted() {
    this.onMessageChange();
  }

  @Emit('onCirclesChange')
  public onCirclesChange() {
    return MapService.getCircleBounds(this.$refs.alertCirclesRef as LCircle[]);
  }

  @Watch('messages')
  public onMessageChange() {
    this.generateCircles(this.messages);
  }

  private generateCircles(messages: MessageAlert[]) {
    this.alertCircles = messages.map((message) => ({
      key: `a${message.stationId}`,
      center: latLng(message.latitude, message.longitude),
      radius: message.radius * 1000,
      color: ORANGE_COLOR,
    }));
    this.onCirclesChange();
  }
}
