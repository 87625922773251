var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-btn',{staticClass:"map__control--btn-secondary",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.drawerCatalogLocation = !_vm.drawerCatalogLocation}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-book-open-page-variant")])],1),_c('v-navigation-drawer',{attrs:{"absolute":"","bottom":"","temporary":"","right":""},model:{value:(_vm.drawerCatalogLocation),callback:function ($$v) {_vm.drawerCatalogLocation=$$v},expression:"drawerCatalogLocation"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',[_c('v-list-item',[_vm._v(" New Catalog Location ")]),(_vm.drawerCatalogLocation)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-list-item',[_c('ValidationProvider',{attrs:{"name":"Latitude","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Latitude","type":"number","messages":touched || valid ? '' : 'Latitude is required',"error-messages":errors},model:{value:(_vm.newCatalogLocation.lat),callback:function ($$v) {_vm.$set(_vm.newCatalogLocation, "lat", $$v)},expression:"newCatalogLocation.lat"}})]}}],null,true)})],1),_c('v-list-item',[_c('ValidationProvider',{attrs:{"name":"Longitude","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Longitude","type":"number","messages":touched || valid ? '' : 'Longitude is required',"error-messages":errors},model:{value:(_vm.newCatalogLocation.long),callback:function ($$v) {_vm.$set(_vm.newCatalogLocation, "long", $$v)},expression:"newCatalogLocation.long"}})]}}],null,true)})],1),_c('v-list-item',[_c('v-text-field',{attrs:{"label":"Magnitude","type":"number"},model:{value:(_vm.newCatalogLocation.magnitude),callback:function ($$v) {_vm.$set(_vm.newCatalogLocation, "magnitude", $$v)},expression:"newCatalogLocation.magnitude"}})],1),_c('v-list-item',[_c('v-text-field',{attrs:{"label":"Depth","type":"number"},model:{value:(_vm.newCatalogLocation.depth),callback:function ($$v) {_vm.$set(_vm.newCatalogLocation, "depth", $$v)},expression:"newCatalogLocation.depth"}})],1),_c('v-list-item',[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.newCatalogLocation.description),callback:function ($$v) {_vm.$set(_vm.newCatalogLocation, "description", $$v)},expression:"newCatalogLocation.description"}})],1),_c('v-list-item',[_c('DateTimeSelector',{attrs:{"date":_vm.newCatalogLocation.date,"time":_vm.newCatalogLocation.dateTime,"hide-events":true,"hide-display-interval":true,"label":"Date/Time","icon":null,"disabled":false,"dense":false},on:{"update:date":function($event){return _vm.$set(_vm.newCatalogLocation, "date", $event)},"update:time":function($event){return _vm.$set(_vm.newCatalogLocation, "dateTime", $event)}}})],1),_c('v-list-item',[_c('v-btn',{attrs:{"small":"","block":"","color":"primary","disabled":invalid},on:{"click":function($event){return _vm.addCatalogLocation()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-book-open-page-variant")]),_vm._v(" Add Catalog Location ")],1)],1)]}}],null,false,3729828747)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }