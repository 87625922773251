






import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { LPolygon } from 'vue2-leaflet';
import { MessageSource } from '@/models/message.model';
import { PURPLE_COLOR } from '@/constants/app.constants';

@Component({
  name: 'MapPolygon',
  components: {
    LPolygon,
  },
})
export default class MapPolygon extends Vue {
  @Prop() public sourceMessage!: MessageSource;

  public PURPLE_COLOR: string = PURPLE_COLOR;
  public polygons: number[][][] = [];

  public mounted() {
    this.onSourceMessageChange();
  }

  @Emit('onPolygonChange')
  public onPolygonChange() {
    return this.polygons;
  }

  @Watch('sourceMessage')
  public onSourceMessageChange() {
    this.generatePolygons(this.sourceMessage);
  }

  private generatePolygons(sourceMessage: MessageSource) {
    this.polygons = sourceMessage?.regions ? sourceMessage.regions.map((region) => region.map((point) => [point.lat, point.long])) : [];
    this.onPolygonChange();
  }
}
