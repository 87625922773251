


































































import { Component, Emit, Vue } from 'vue-property-decorator';
import { CatalogLocation } from '@/models/event.model';
import DateTimeSelector from '@/components/shared/DateTimeSelector.component.vue';

@Component({
  name: 'MapDrawerCatalogLocation',
  components: { DateTimeSelector },
})
export default class MapDrawerCatalogLocation extends Vue {
  public drawerCatalogLocation: boolean = false;
  public newCatalogLocation: CatalogLocation = new CatalogLocation();

  public addCatalogLocation() {
    this.onAdd();
    this.drawerCatalogLocation = false;
    this.newCatalogLocation = new CatalogLocation();
  }

  @Emit()
  public onAdd() {
    return this.newCatalogLocation;
  }
}
