



























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { latLng } from 'leaflet';
import { LCircle } from 'vue2-leaflet';
import { MessageSource } from '@/models/message.model';
import { WaveCircle } from '@/models/map.model';
import { GREEN_COLOR, RED_COLOR } from '@/constants/app.constants';
import { MapService } from '@/services/map.service';

@Component({
  name: 'MapWaveCircles',
  components: {
    LCircle,
  },
})
export default class MapWaveCircles extends Vue {
  @Prop() public sourceMessage!: MessageSource;
  @Prop() public visible?: boolean;

  public pWaveCircles: WaveCircle[] = [];
  public sWaveCircles: WaveCircle[] = [];

  public mounted() {
    this.onSourceMessageChange();
  }

  @Emit('onCirclesChange')
  public onCirclesChange() {
    return _.union(
      MapService.getCircleBounds(this.$refs.sWaveCirclesRef as LCircle[]),
      MapService.getCircleBounds(this.$refs.pWaveCirclesRef as LCircle[]),
    );
  }

  @Watch('sourceMessage')
  public onSourceMessageChange() {
    this.generateWaveCircles(this.sourceMessage);
  }

  private generateWaveCircles(sourceMessage: MessageSource) {
    if (sourceMessage.location) {
      // HACK: Remove post-demo
      const ratio = 1.3;
      const timeCorrectionFactor = 7; // seconds
      const center = latLng(sourceMessage.location.lat, sourceMessage.location.long);

      this.pWaveCircles = sourceMessage.pWaveFrontDistance
        ? [
            {
              key: `p${sourceMessage.eventId}`,
              center,
              // radius: sourceMessage.pWaveFrontDistance * 1000 * ratio,
              radius: (sourceMessage.millisSinceFirstPDetected / 1000 + timeCorrectionFactor) * 5,
              color: GREEN_COLOR,
            },
          ]
        : [];
      this.sWaveCircles = sourceMessage.sWaveFrontDistance
        ? [
            {
              key: `s${sourceMessage.eventId}`,
              center,
              // radius: sourceMessage.sWaveFrontDistance * 1000 * ratio,
              radius: (sourceMessage.millisSinceFirstPDetected / 1000 + timeCorrectionFactor) * 3,
              color: RED_COLOR,
            },
          ]
        : [];
    } else {
      this.pWaveCircles = [];
      this.sWaveCircles = [];
    }
    this.onCirclesChange();
  }
}
