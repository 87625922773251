import { ChartConfiguration } from '@/models/chart.model';
import { Message, MessageAlert, MessageSource, MessageVIDA } from '@/models/message.model';
import { LatLongObject, MinMaxObject } from '@/models/app.model';
import { PointOfInterestComplete } from '@/models/position.model';
import { StationType } from '@/constants/station.constants';

export interface EventSortQueryParams {
  property: string;
  type: string;
}

export interface EventsQueryParams {
  count: number; // pagination
  start: number; // pagination
  stationIds: string[] | null;
  timeRange: {
    from: number;
    to: number;
  } | null;
  textSearch: string;
  magnitudeMin: number | null;
  magnitudeMax: number | null;
  sort?: EventSortQueryParams;
}

export interface EventDataQueryParams {
  from: number | null;
  to: number | null;
  nodes: number[] | null;
  channels: number[] | null;
  readingFilterType: string | null;
  includeSeries: boolean;
  downSample: boolean;
  preferredUnits: string | null;
}

export interface EventRegionalQueryParams {
  from: number | null;
  to: number | null;
  readingFilterType: string | null;
  includeSeries: boolean;
  observedTime: number | null;
  preferredUnits: string | null;
}

export interface EventsQueryResponse {
  count: number;
  elements: HumanizedEvent[];
  start: number;
  totalCount: number;
}

export interface Event {
  endTime: number;
  eventId: string;
  name: string;
  startTime: number;
  stationIds: { stationId: string; stationEventId: string }[];
  stationId: string;
  reportedByStationId: string;
  regionalEventId: string;
  teleseismicity: number;
  additionalLocations?: EventAdditionalLocation[];
  eqLocation: LatLongObject;
  publishable: boolean;
  published: boolean;
}

export interface EventOriginalDataFilter {
  filterType: string;
  low: number;
  high: number;
}

export interface EventRegional {
  deltaTauLine: [number, number][];
  endTime: number;
  location: LatLongObject;
  eventId: string;
  firstSourceMessage: MessageSource;
  lastSourceMessage: MessageSource;
  lastLocalMessagesByStationAndType: { [key: string]: { [key: string]: Message } };
  magLine: [number, number][];
  magnitude: MinMaxObject;
  moment: MinMaxObject;
  pointsOfInterest: PointOfInterestComplete[];
  readings: {
    channelId: number;
    data: [number, number][];
    nodeId: number;
    stationId: string;
    units: string;
  }[];
  shezifAlertMessagesSinceStart: MessageAlert[];
  startTime: number;
  stationEvents: StationEventRef[];
  stressDrop: MinMaxObject;
  additionalLocations?: EventAdditionalLocation[];
  originalDataFilter: EventOriginalDataFilter;
  hasOnlySingleStations: boolean;
  publishable: boolean;
  published: boolean;
  vidaMessages: MessageVIDA[];
}

export interface StationEventRef {
  eventId: string;
  stationId: string;
  startTime: number;
  endTime: number;
  mainChannelId: number;
  type?: StationType;
}

export interface HumanizedRegionalEvent extends EventRegional {
  parsedDate: string;
  parsedDateInverse: string;
  parsedOriginTimeInverse: string;
  parsedOriginTime: string;
  parsedLocation: string;
  parsedMagnitude: string;
  parsedMagnitudeSimple: string;
  parsedMoment: string;
  parsedStressDrop: string;
}

export interface HumanizedEvent extends Event {
  duration: number;
  paddedDuration: number;
  endDate: string;
  startDate: string;
  startDateTime: string;
  displayStartTimestamp: string;
  paddedStartTimestamp: string;
}

export interface CompleteEvent extends Event {
  bazLine: [number, number][];
  channelIds: number[];
  channelReadings: {
    channelId: number;
    nodeId: number;
    units: string;
    data: [number, number][];
  }[];
  magLine: [number, number][];
  magnitude: MinMaxObject;
  nodeIds: number[];
  pBaz: number;
  pBeamLine: [number, number][];
  peakAmp: number;
  pPickTime: number;
  pSlo: number;
  qFactorLine: [number, number][];
  rmsAmp: number;
  sampleRate: number;
  sBeamLine: [number, number][];
  sloLine: [number, number][];
  sPickTime: number;
  event: Event;
  metaSeries: EventDataMetaSeries[];
  nyasTime: number | null;
  originalDataFilter: EventOriginalDataFilter;
}

export interface EventDataMetaSeries {
  key: string;
  name: string;
  units?: string;
  values: [number];
}

export interface CompleteHumanizedEvent extends CompleteEvent, HumanizedEvent {
  extraReadings: ChartConfiguration[];
  spDuration: string | number;
  proxyDistance: string;
  parsedMagnitude: string;
}

export class CatalogLocation {
  public id: number | undefined;
  public lat: number | null = null;
  public long: number | null = null;
  public depth: number | null = null;
  public magnitude: number | null = null;
  public description: string | null = null;
  public dateTime: string | undefined = undefined;
  public time: string | number | undefined = undefined;
  public date: string | undefined = undefined;
}

export interface EventAdditionalLocation {
  gpsCoords3D: { lat: number; long: number; alt: number };
  description: string;
  createdAt: number;
  magnitude: number | null;
  time: number | null;
}
