
















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { latLng } from 'leaflet';
import { LCircle } from 'vue2-leaflet';
import { MessageSource } from '@/models/message.model';
import { WaveCircle } from '@/models/map.model';
import { GREEN_COLOR, RED_COLOR, SHAKE_MAP_COLORS } from '@/constants/app.constants';

@Component({
  name: 'MapIntensityCircles',
  components: {
    LCircle,
  },
})
export default class MapIntensityCircles extends Vue {
  @Prop() public sourceMessage!: MessageSource;
  @Prop() public visible?: boolean;

  public intensityCircles: WaveCircle[] = [];

  public mounted() {
    this.onSourceMessageChange();
  }

  @Watch('sourceMessage')
  public onSourceMessageChange() {
    this.generateShakeMap(this.sourceMessage);
  }

  private generateShakeMap(sourceMessage: MessageSource) {
    this.intensityCircles =
      sourceMessage.location && sourceMessage.intensityRadii
        ? sourceMessage.intensityRadii.map((value: number, index: number) => ({
            key: `shake${index}`,
            center: [sourceMessage.location.lat, sourceMessage.location.long],
            radius: value * 1000,
            color: SHAKE_MAP_COLORS[index],
          }))
        : [];
  }
}
