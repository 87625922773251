














import { Component, Prop, Vue } from 'vue-property-decorator';
import { LMarker, LTooltip } from 'vue2-leaflet';
import { MessageSource } from '@/models/message.model';
import { SeismicPosition } from '@/models/position.model';
import { iconCatalogLocation, iconEpicenter } from '@/constants/leaflet.constants';

@Component({
  name: 'MapEpicenter',
  components: {
    LMarker,
    LTooltip,
  },
})
export default class MapEpicenter extends Vue {
  @Prop() public sourceMessage!: MessageSource;
  @Prop() public catalogEpicenter!: SeismicPosition;

  public leafletEpicenterIcon: any = this.catalogEpicenter ? iconCatalogLocation : iconEpicenter;

  public showCatalogEpicenter() {
    return this.catalogEpicenter && (this.catalogEpicenter.lat !== 0 || this.catalogEpicenter.long !== 0);
  }
}
