import { LCircle } from 'vue2-leaflet';

export class MapService {
  public static getCircleBounds(circleRefs: LCircle[]): number[][][] {
    if (!circleRefs) {
      return [];
    }

    return circleRefs.map((circle) => {
      const bounds = circle.mapObject.getBounds();

      if (bounds) {
        return [
          [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
          [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
        ];
      }
      return [];
    });
  }
}
