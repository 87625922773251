
















import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LPolygon } from 'vue2-leaflet';
import { GREEN_COLOR, RED_COLOR } from '@/constants/app.constants';
import { MessageSloBazDetermined } from '@/models/message.model';
import { Station } from '@/models/station.model';
import { GeometryUtils } from '@/utils/geometry.utils';

@Component({
  name: 'MapCones',
  components: {
    LPolygon,
  },
})
export default class MapCones extends Vue {
  @Prop() public sohMessages!: MessageSloBazDetermined[];
  @Prop() public sloBazMessages!: MessageSloBazDetermined[];
  @Prop() public stations!: Station[];
  @Prop() public showSoh!: boolean;

  public GREEN_COLOR: string = GREEN_COLOR;
  public RED_COLOR: string = RED_COLOR;
  public sloBazLines: { [key: string]: number[][] } = {};
  public sloBazPrimaryLines: { [key: string]: number[][] } = {};
  public sohLines: { [key: string]: number[][] } = {};

  public mounted() {
    this.onSloBazMessagesChange();
    this.onSOHMessagesChange();
  }

  @Watch('sloBazMessages')
  public onSloBazMessagesChange() {
    // reverse to only keep the last message for each station
    _.reverse(this.sloBazMessages);
    this.sloBazLines = this.getLinesFromMessages(this.sloBazMessages, 'sloBaz');
    this.sloBazPrimaryLines = this.getSloBazPrimaryLines(this.sloBazMessages);
  }

  @Watch('sohMessages')
  public onSOHMessagesChange() {
    _.reverse(this.sohMessages);
    this.sohLines = this.showSoh ? this.getLinesFromMessages(this.sohMessages, 'soh') : {};
  }

  private getLinesFromMessages(messages: MessageSloBazDetermined[], type: string): { [key: string]: number[][] } {
    const polyLine: { [key: string]: number[][] } = {};

    messages.forEach((message) => {
      if (polyLine[`${message.stationId}__${type}`]) {
        return;
      }
      const station = _(this.stations).find({ stationId: message.stationId });
      if (!station) {
        return;
      }

      const sohConeWidth = GeometryUtils.convertQualityToAngle(message.quality || 0);
      const minAngle = type === 'sloBaz' ? message.minBaz : message.baz - sohConeWidth / 2;
      const maxAngle = type === 'sloBaz' ? message.maxBaz : message.baz + sohConeWidth / 2;

      const [pointALong, pointALat] = GeometryUtils.rotatePoint(station.location.long, station.location.lat, minAngle);
      const [pointBLong, pointBLat] = GeometryUtils.rotatePoint(station.location.long, station.location.lat, maxAngle);

      polyLine[`${message.stationId}__${type}`] = [
        [station.location.lat, station.location.long],
        [pointALat, pointALong],
        [pointBLat, pointBLong],
        [station.location.lat, station.location.long],
      ];
    });

    return polyLine;
  }

  private getSloBazPrimaryLines(messages: MessageSloBazDetermined[]): { [key: string]: number[][] } {
    const lines: { [key: string]: number[][] } = {};

    messages.forEach((message) => {
      if (lines[`${message.stationId}__primaryBaz`]) {
        return;
      }
      const station = _(this.stations).find({ stationId: message.stationId });
      if (!station) {
        return;
      }

      const angle = message.baz;
      if (angle !== -1) {
        const [pointLong, pointLat] = GeometryUtils.rotatePoint(station.location.long, station.location.lat, angle);

        lines[`${message.stationId}__primaryBaz`] = [
          [station.location.lat, station.location.long],
          [pointLat, pointLong],
        ];
      }
    });

    return lines;
  }
}
