






















































import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import { FilterFlags, FilterFlagsEnableStatus } from '@/models/map.model';

@Component({
  name: 'MapDrawerFilters',
  components: {},
})
export default class MapDrawerFilters extends Vue {
  public drawer: boolean = false;
  public filterFlags: FilterFlags = new FilterFlags();
  public filterFlagsEnableStatus: FilterFlagsEnableStatus = new FilterFlagsEnableStatus();

  public mounted() {
    if (this.$route.name) {
      this.filterFlags = new FilterFlags(this.$route.name);
      this.filterFlagsEnableStatus = new FilterFlagsEnableStatus(this.$route.name);
    }
    this.change();
  }

  @Watch('filterFlags', { deep: true })
  public onFiltersChange() {
    this.change();
  }

  @Emit()
  public change() {
    return this.filterFlags;
  }
}
